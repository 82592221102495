import React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../components/Seo"
import PageWrapper from "../components/PageWrapper"

const BrandSinglePage = props => {
  const {
    pageContext: { name, lang },
    data: {
      allModel: { nodes },
    },
  } = props

  return (
    <PageWrapper>
      <Seo
        title={lang === "en" ? `${name} Tools` : `Outils ${name}`}
        lang={lang}
      />

      <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 py-4 sm:py-8 text-center">
          <h1 className="mt-2 mb-2 text-4xl font-extrabold tracking-tight sm:text-5xl">
            {name}
          </h1>

          <div>
            {nodes?.map?.(model => {
              return (
                <div key={model.id}>
                  <Link
                    className="text-[2rem] leading-[52px] tracking-[0.5px]"
                    to={
                      lang === "en" ? `/${model.uri}` : `/${lang}/${model.uri}`
                    }
                  >
                    {model.name}
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </main>
    </PageWrapper>
  )
}

export default BrandSinglePage

export const brandQuery = graphql`
  query brandQuery($name: String!) {
    allModel(filter: { brand: { eq: $name } }) {
      nodes {
        brand
        id
        name
        uri
      }
    }
  }
`
